export const toolbarLists = [
  {
    menu: "setting",
  },
  {
    menu: "sound",
  },
  {
    menu: "history",
  },
  {
    menu: "question",
  },
  {
    menu: "support",
  },
];
