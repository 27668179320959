import React, { createContext, useState, useContext } from "react";

const SettingContext = createContext();

export const useSetting = () => useContext(SettingContext);

export const SettingProvider = ({ children }) => {
  const [settings, setSettings] = useState({ theme: "light" });

  const updateSettings = (newSettings) => {
    setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
  };

  return (
    <SettingContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingContext.Provider>
  );
};
