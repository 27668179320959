import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAmount } from "../store/callBet";
import { useSocket } from "../contexts/socketContext";

const SelectBuySell = () => {
  const dispatch = useDispatch();
  const betTransaction = useSelector((state) => state.callBet);
  const timeCount = useSelector((state) => state.joinGame.countDown);
  const { emitAction } = useSocket();

  // console.warn("betTransaction", betTransaction);

  const handleClickLong = (type) => {
    if (!timeCount.isShow || betTransaction.amount < 1) return;
    emitAction("buy", { type: type, amount: betTransaction.amount }, (cb) => {
      console.warn("callllllllllllll", cb);
    });
    if (betTransaction.type === type) {
      return;

      // dispatch(changeAmount({ type: "", amount: 100 }));
    } else {
      dispatch(changeAmount({ type: type, amount: betTransaction.amount }));
    }
  };
  return (
    <main className="w-[384px] flex flex-col">
      <button
        className="h-[84px] bg-[#D9D9D9] flex justify-center items-center px-[60px] bg-button-up active:bg-button-up-active"
        style={{
          borderRadius: "24px 24px 8px 8px",
          opacity: betTransaction.type === "put" ? 0.5 : 1,
          border:
            betTransaction.type === "call"
              ? "4px solid #BAE0DD"
              : "1px solid #BAE0DD",
        }}
        onClick={() => {
          handleClickLong("call");
        }}
      >
        <p
          className="text-[34px] flex flex-1 justify-center items-center"
          style={{
            color: betTransaction.type === "call" ? "#E9F5F4" : "#BAE0DD",
          }}
        >
          UP
        </p>
        <img
          src="/img/icon/icon-up.png"
          alt="up icon"
          className="w-[36px] h-[36px]"
        />
      </button>

      <button
        className="h-[84px] bg-[#D9D9D9] flex justify-center items-center px-[60px] bg-button-down active:bg-button-down-active"
        style={{
          borderRadius: "8px 8px 24px 24px",
          opacity: betTransaction.type === "call" ? 0.5 : 1,
          border:
            betTransaction.type === "put"
              ? "4px solid #D9D9D9"
              : "1px solid #D9D9D9",
        }}
        onClick={() => {
          handleClickLong("put");
        }}
      >
        <p
          className="text-[34px] flex flex-1 justify-center items-center"
          style={{
            color: betTransaction.type === "put" ? "#FDEDED" : "#FAC8C8",
          }}
        >
          DOWN
        </p>
        <img
          src="/img/icon/icon-down.png"
          alt="up icon"
          className="w-[36px] h-[36px]"
        />
      </button>
    </main>
  );
};

export default SelectBuySell;
