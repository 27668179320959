export const dataButtonBet = [
  {
    number: 1,
  },
  {
    number: 2,
  },
  {
    number: 5,
  },
  {
    number: 10,
  },
  {
    number: 25,
  },
  {
    number: 50,
  },
];
