import { createSlice } from "@reduxjs/toolkit";

const joinGameSlice = createSlice({
  name: "joingame",
  initialState: {
    balance: 0,
    startTime: 0,
    endTime: 0,
    countDown: { isShow: false, remain: 0, maxCount: 5 },
    portforlio: 0,
  },
  reducers: {
    getBalance: (state, action) => {
      state.balance = action.payload;
    },

    startCountdown: {
      reducer: (state, action) => {
        state.countDown.isShow = true;
        state.countDown.remain = action.payload.remain;
        state.countDown.maxCount = action.payload.maxCount;
      },
      prepare: (remain, maxCount = 5) => {
        return { payload: { remain, maxCount } };
      },
    },
    resetCountdown: (state) => {
      state.countDown.isShow = false;
    },
    setPortforlio: (state, action) => {
      state.portforlio = action.payload;
    },
  },
});

export const { getBalance, startCountdown, resetCountdown, setPortforlio } =
  joinGameSlice.actions;

export default joinGameSlice.reducer;
