import React from "react";
import Wallet from "./wallet";
import LiveTrend from "./liveTrend";
import Toolbar from "./toolbar";

const Header = () => {
  return (
    <div className="w-full flex justify-between">
      <Wallet />
      <LiveTrend />
      <Toolbar />
    </div>
  );
};

export default Header;
