import React, { useEffect } from "react";
import Console from "./components/console";
import Header from "./components/header/header";
import { ModalPhaseBet } from "./components/modalPhaseBet/modalPhaseBet";
import { useSelector } from "react-redux";

function App() {
  return (
    <main className="w-full min-h-screen flex flex-col">
      <div className="flex flex-1 h-full relative">
        <div className="w-full flex-1 flex gap-8 relative">
          <iframe
            src="https://play.xylogaming.com/testing/crash/stock-millionaire-graph/"
            className="w-full h-full "
            title="Game"
          />
          <section
            className="absolute w-full h-full flex justify-center items-center"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
          >
            <ModalPhaseBet />
          </section>
          {/* <section className="w-[352px] h-full bg-neutral-800">
            <p>winner</p>
          </section> */}
        </div>
        <div className="absolute w-full px-[72px] py-[24px]">
          <Header />
        </div>
      </div>

      <section className="w-full">
        <Console />
      </section>
    </main>
  );
}

export default App;
