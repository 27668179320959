import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import {
  getBalance,
  setCountdownPhase,
  setPortforlio,
  startCountdown,
} from "../store/joinGame";
import { calculateHaveCountTime } from "../utils/countTime";
import { changeAmount, resetBet } from "../store/callBet";
import { setLivetrend } from "../store/liveTrend";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramId = urlParams.get("pfuid");
    const randomParamId = Date.now();

    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
      transports: ["websocket"],
      pingInterval: 10000,
      pingTimeout: 5000,
      query: {
        userInfo: JSON.stringify({
          pfid: "62130b00bc1acf58340948ae",
          pfuid: paramId || `ID_${randomParamId}`,
          // pfuid: "ID_1715934942481",
          pfutk: "TOKEN_1715934942481",
          gid: "660fc769800a505ea2351044",
        }),
      },
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        // console.warn("Connected to server");
      });

      socket.on("joinGame", (e) => {
        console.warn("---->joinGameWeb:", e);
        dispatch(getBalance(e?.balance));
        dispatch(setPortforlio(e.gameContent.portfolio));
        const remainTime = calculateHaveCountTime(e.endBetDate, e.serverTime);
        if (remainTime > 0) {
          // console.warn("remainTime", remainTime);

          dispatch(startCountdown(remainTime));
        }
      });

      socket.on("data", (e) => {
        console.warn("---->dataWeb:", e);
      });

      socket.on("inGameResponse", (e) => {
        console.warn("---->inGameResponseWeb:", e);
        if (e.state === "start") {
          const remainTime = calculateHaveCountTime(e.endTime, e.startTime);
          if (remainTime > 0) {
            dispatch(startCountdown(remainTime));
          }
        }

        if (e.state === "end") {
          dispatch(resetBet());
          dispatch(getBalance(e?.balance));
          dispatch(setPortforlio(e.portfolio));
        }
      });

      socket.on("liveTrend", (e) => {
        console.warn("---->liveTrendWeb:", e);
        dispatch(setLivetrend(e));
      });

      socket.on("disconnect", () => {
        // console.warn("---->Disconnected from serverWeb");
      });

      // Cleanup listeners on component unmount
      return () => {
        socket.off("connect");
        socket.off("joinGame");
        socket.off("data");
        socket.off("inGameResponse");
        socket.off("liveTrend");
        socket.off("disconnect");
      };
    }
  }, [socket]);

  const emitAction = useCallback(
    (action, data, callback) => {
      if (socket) {
        socket.emit("action", JSON.stringify({ action, data }), callback);
      }
    },
    [socket]
  );

  return (
    <SocketContext.Provider value={{ socket, emitAction }}>
      {children}
    </SocketContext.Provider>
  );
};
