import Portfolio from "./portfolio";
import SelectAmountBet from "./selectAmountBet";
import SelectBuySell from "./selectBuySell";
const Console = () => {
  return (
    <main className=" px-[72px] py-6 w-full flex  justify-center items-center gap-8">
      <SelectAmountBet />
      <SelectBuySell />
      <Portfolio />
    </main>
  );
};

export default Console;
