import React from "react";
import AmountButton from "./amountButton";
import { dataButtonBet } from "../static/betData";
import { useDispatch, useSelector } from "react-redux";
import { setAmount } from "../store/callBet";

const SelectAmountBet = () => {
  const dispatch = useDispatch();
  const betTransaction = useSelector((state) => state.callBet);
  const balance = useSelector((state) => state.joinGame);
  const timeCount = useSelector((state) => state.joinGame.countDown);

  const style = {
    borderRadius: "24px",
    border: "1px solid var(--Base-Secondary-secondary-400, #6F8DF7)",
    background:
      "var(--Gradient-Neutral-neu-3, linear-gradient(0deg, var(--Base-Primary-primary-900, #1A5163) 0%, var(--Base-Primary-primary-900, #1A5163) 100%), linear-gradient(180deg, #3550AE 0%, #362A93 100%))",
    backgroundBlendMode: "luminosity, normal",
    width: "384px",
  };

  const handleChangeAmount = (amount) => {
    // if (!timeCount.isShow) return;

    if (betTransaction.amount + amount > balance.balance) {
      dispatch(setAmount(balance.balance));
    } else {
      dispatch(setAmount(amount));
    }
  };

  return (
    <main
      style={style}
      className="flex flex-col justify-center items-center p-4 gap-2"
    >
      <p className="text-[20px]" style={{ color: "#C3ECF9" }}>
        Place Bets
      </p>
      <section className="flex flex-col flex-1 gap-2 w-full">
        <div className="flex gap-2 flex-1 w-full">
          <button
            className="w-[36px] h-[36px] rounded-[12px] border border-solid flex justify-center items-center bg-gradient-button-minus active:bg-gradient-button-minus-active active:pt-2"
            style={{
              borderColor: "#FDEDED",
            }}
          >
            <img src="/img/icon/minusImg.png" alt="minus icon" />
          </button>

          <div
            className="flex-1 flex justify-center items-center rounded-[12px]"
            style={{
              background:
                "var(--Gradient-Neutral-neu-1, linear-gradient(0deg, var(--Base-Tertiary-tertiary-900, #201957) 0%, var(--Base-Tertiary-tertiary-900, #201957) 100%), linear-gradient(180deg, #202F67 0%, #201957 100%))",
            }}
          >
            <p className="text-[24px]" style={{ color: "#FFF455" }}>
              $ {betTransaction.amount.toFixed(2).toLocaleString() || 0.0}
            </p>
          </div>
          <button
            className="w-[36px] h-[36px] rounded-[12px] border border-solid flex justify-center items-center bg-gradient-button-plus active:bg-gradient-button-plus-active active:pt-2"
            style={{
              borderColor: "#FDEDED",
            }}
          >
            <img
              src="/img/icon/plusImg.png"
              alt="minus icon"
              className="w-[22px] h-[22px]"
            />
          </button>
        </div>
      </section>

      <section className="grid grid-cols-3 gap-2 w-full">
        {dataButtonBet.map((item, _) => {
          return (
            <button
              key={`button` + item.number}
              className="rounded-[8px] border border-solid border-[#ECF9FD] flex justify-center items-center h-[24px] flex-1 min-w-[112px] bg-gradient-button-1 active:bg-gradient-button-1-active active:pt-1"
              onClick={() => {
                handleChangeAmount(item.number);
              }}
            >
              ${item.number}
            </button>
          );
        })}
      </section>
    </main>
  );
};

export default SelectAmountBet;
