import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCountdown } from "../../store/joinGame";

export const ModalPhaseBet = () => {
  const dispatch = useDispatch();
  const timeCount = useSelector((state) => state.joinGame.countDown);
  const [countdownTime, setCountDownTime] = useState(0);
  const [widthTime, setWidthTime] = useState(0);

  useEffect(() => {
    if (timeCount.isShow) {
      setCountDownTime(timeCount.remain);
      setWidthTime((timeCount.remain / timeCount.maxCount) * 100);
    }
  }, [timeCount.isShow]);

  useEffect(() => {
    if (!timeCount.isShow) {
      return;
    }

    const interval = setInterval(() => {
      setCountDownTime((prev) => {
        if (prev <= 0.5) {
          clearInterval(interval);
          dispatch(resetCountdown());
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownTime]);

  useEffect(() => {
    setWidthTime((countdownTime / timeCount.maxCount) * 100);
  }, [countdownTime]);

  const getBackgroundColor = (widthTime) => {
    const greenValue = Math.floor((widthTime / 100) * 255);
    const redValue = 255 - greenValue;
    return `rgb(${redValue}, ${greenValue}, 0)`;
  };

  if (!timeCount.isShow) {
    return null;
  }

  return (
    <main
      className="max-w-[352px] h-[160px] rounded-[24px] bg-modal-phase-bet flex flex-col flex-1 overflow-hidden"
      style={{ border: "1px solid #6F8DF7", backgroundBlendMode: "luminosity" }}
    >
      <section className="flex-1 px-[32px] flex justify-center items-center gap-8">
        <div className="flex flex-col">
          <p className="text-[16px]" style={{ color: "#C3ECF9" }}>
            Place Your Bets In
          </p>
          <p
            className="text-right text-[60px] font-semibold"
            style={{ lineHeight: "65px" }}
          >
            {Math.max(countdownTime, 0).toFixed()}s
          </p>
        </div>
        <img
          src="/img/icon/icon-time.png"
          alt="icon-time"
          className="w-[96px] h-[96px]"
        />
      </section>
      <div
        className="w-full h-[24px] relative"
        style={{
          borderTop: "1px solid #ACBDFA",
          borderLeft: "1px solid #ACBDFA",
          borderRight: "1px solid #ACBDFA",
          borderBottom: "none",
          background: "#1B1333",
        }}
      >
        <div
          className="absolute top-0 left-0 h-full"
          style={{
            width: `${widthTime}%`,
            background: getBackgroundColor(widthTime),
            transition: "width 1s linear, background-color 1s linear",
          }}
        ></div>
      </div>
    </main>
  );
};
