import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../contexts/socketContext";
import { getBalance, setPortforlio } from "../store/joinGame";

const Portfolio = () => {
  const { emitAction } = useSocket();
  const dispatch = useDispatch();
  const portforlio = useSelector((state) => state.joinGame.portforlio);
  const timeCount = useSelector((state) => state.joinGame.countDown);

  const handleCollectPortfolio = () => {
    if (!timeCount.isShow) return;

    emitAction("buy", { type: "collect" }, (cb) => {
      console.log("cb-collect", cb);
      if (cb.code === "SUCCESS") {
        dispatch(setPortforlio(0));
        dispatch(getBalance(cb?.result?.data?.wallet?.balance));
      }
    });
  };

  return (
    <main
      className="w-[384px] flex flex-col  rounded-[24px] border border-solid opacity-80 h-[160px]"
      style={{
        borderColor: "var(--Base-Secondary-secondary-400, #6F8DF7)",
        background:
          "var(--Gradient-Neutral-neu-2, linear-gradient(0deg, var(--Base-Secondary-secondary-900, #202F67) 0%, var(--Base-Secondary-secondary-900, #202F67) 100%), linear-gradient(180deg, #293E87 0%, #2A2072 100%))",
        backgroundBlendMode: "luminosity, normal",
      }}
    >
      <div className="flex flex-col items-center w-full flex-1 justify-center">
        <p className="text-[20px]" style={{ color: "#C3ECF9" }}>
          Portfolio
        </p>
        <p className="text-[24px]" style={{ color: "#FFFEEE" }}>
          $ {portforlio.toFixed(2).toLocaleString()}
        </p>
      </div>
      <button
        className="flex flex-1 flex-col items-center justify-center border border-solid bg-button-cashout  active:bg-button-cashout-active"
        style={{
          borderColor: "#FFFEEE",
          borderRadius: "8px 8px 24px 24px",
        }}
        onClick={() => {
          handleCollectPortfolio();
        }}
      >
        <p
          style={{ fontWeight: 600, color: "#FFFEEE" }}
          className="text-[24px]"
        >
          CASH OUT
        </p>

        <p className="text-[12px]" style={{ color: "#FFFEEE" }}>
          1% Commission Fee
        </p>
      </button>
    </main>
  );
};

export default Portfolio;
