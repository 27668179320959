import React from "react";
import { useSelector } from "react-redux";

const Wallet = () => {
  const balance = useSelector((state) => state.joinGame.balance);

  return (
    <section className="flex gap-2 justify-center">
      <img
        src="/img/icon/icon-wallet.png"
        alt="icon-wallet"
        className="w-[28px] h-[28px]"
      />
      <p>Balance :</p>
      <div
        className="rounded-[12px] px-[16px] flex items-center w-[187px] h-[36px]"
        style={{ background: "#1B1333" }}
      >
        $ {balance.toLocaleString() || 0}
      </div>
    </section>
  );
};

export default Wallet;
