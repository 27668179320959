import { configureStore } from "@reduxjs/toolkit";
import callBetReducer from "./callBet";
import joinGameReducer from "./joinGame";
import liveTrendReducer from "./liveTrend";

const store = configureStore({
  reducer: {
    callBet: callBetReducer,
    joinGame: joinGameReducer,
    livetrend: liveTrendReducer,
  },
});

export default store;
