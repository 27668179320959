export const ButtonToolbar = (img) => {
  return (
    <button className="w-[48px] h-[48px] rounded-[16px] flex justify-center items-center border border-[#ECF9FD] bg-gradient-button-1 active:bg-gradient-button-1-active active:pt-2">
      <img
        src={`/img/icon/icon-${img.img}.png`}
        alt={`menu${img.img}`}
        className="w-[28px] h-[28px]"
        style={{
          filter:
            "drop-shadow(0px 0px 4px var(--Base-Quaternary-quaternary-900, #1B1333))",
        }}
      />
    </button>
  );
};
