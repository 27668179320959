import { createSlice } from "@reduxjs/toolkit";

const callBetSlice = createSlice({
  name: "callbet",
  initialState: {
    type: "",
    amount: 0,
  },
  reducers: {
    changeAmount: (state, action) => {
      const { type, amount } = action.payload;
      state.type = type;
      state.amount = amount;
    },
    setAmount: (state, action) => {
      if (state.amount + action.payload > 100) {
        state.amount = 100;
      } else {
        state.amount = state.amount + action.payload;
      }
    },
    resetBet: (state) => {
      state.type = "";
      state.amount = 0;
    },
  },
});

export const { changeAmount, resetBet, setAmount } = callBetSlice.actions;

export default callBetSlice.reducer;
