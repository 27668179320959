import React from "react";
import { toolbarLists } from "../../static/toolbarData";
import { ButtonToolbar } from "../button/buttonToolbar";

const Toolbar = () => {
  return (
    <main className="flex gap-2">
      {toolbarLists.map((item) => {
        return <ButtonToolbar key={item.menu} img={item.menu} />;
      })}
    </main>
  );
};

export default Toolbar;
