import { createSlice } from "@reduxjs/toolkit";

const liveTrendSlice = createSlice({
  name: "livetrend",
  initialState: {
    liveTrendBet: [],
  },
  reducers: {
    setLivetrend: (state, action) => {
      state.liveTrendBet = action.payload;
    },
  },
});

export const { setLivetrend } = liveTrendSlice.actions;

export default liveTrendSlice.reducer;
