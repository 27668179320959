import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LiveTrend = () => {
  const liveTrend = useSelector((state) => state.livetrend.liveTrendBet);
  const [percentBetLong, setPercentBetLong] = useState(50);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setPercentBetLong((prev) => {
  //       const change = Math.floor(Math.random() * 10) + 1;
  //       const increase = Math.random() < 0.5;
  //       const newPercent = increase ? prev + change : prev - change;
  //       return Math.max(0, Math.min(newPercent, 100));
  //     });
  //   }, 500);
  //   return () => clearInterval(interval);
  // }, [liveTrend]);

  useEffect(() => {
    if ((liveTrend[0] && liveTrend[2]) === 0) {
      setPercentBetLong(50);
    }

    if ((liveTrend[1] || liveTrend[3]) > 0) {
      let totalPool = liveTrend[1] + liveTrend[3];
      let resultPool = (liveTrend[1] / totalPool) * 100;

      setPercentBetLong(resultPool);
    }
  }, [liveTrend]);

  return (
    <main
      className="p-2 rounded-[24px] flex flex-col justify-center items-center gap-2 w-[592px]"
      style={{
        border: "1px solid #6F8DF7",
      }}
    >
      <p
        className="test-[20px]"
        style={{
          color: "#C3ECF9",
          backgroundColor:
            "var(--Gradient-Neutral-neu-2, linear-gradient(0deg, var(--Base-Secondary-secondary-900, #202F67) 0%, var(--Base-Secondary-secondary-900, #202F67) 100%), linear-gradient(180deg, #293E87 0%, #2A2072 100%)",
        }}
      >
        Live Trend
      </p>
      <section
        className="w-full flex items-center justify-between h-[48px] rounded-[16px] overflow-hidden relative"
        style={{
          border: "1px solid var(--Base-Secondary-secondary-400, #6F8DF7)",
        }}
      >
        <div
          className="absolute top-0 left-0 h-full bg-live-trend-long z-10"
          style={{
            width: `${percentBetLong}%`,
            transition: "width 1s ease",
          }}
        ></div>
        <div className="absolute top-0 left-0 w-full h-full bg-live-trend-short"></div>

        <div className="flex gap-[10px] h-full pl-[24px] z-10">
          <p className="text-[34px]" style={{ color: "#BAE0DD" }}>
            {percentBetLong}%
          </p>
          <div className="flex flex-col h-full justify-center items-center">
            <p className="text-[12px]" style={{ color: "#BAE0DD" }}>
              $
              {liveTrend && liveTrend[1]
                ? parseFloat(liveTrend[1]).toFixed(0).toLocaleString()
                : "0"}
            </p>
            <div className="flex gap-1">
              <img
                src="/img/icon/icon-user.png"
                alt="minus icon"
                className="w-[22px] h-[22px]"
              />
              <p className="text-[12px]" style={{ color: "#BAE0DD" }}>
                {liveTrend[0] || 0}
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] h-full pr-[24px] z-10">
          <p className="text-[34px]" style={{ color: "#FAC8C8" }}>
            {100 - percentBetLong}%
          </p>
          <div className="flex flex-col h-full justify-center items-center">
            <p className="text-[12px]" style={{ color: "#FAC8C8" }}>
              ${" "}
              {liveTrend && liveTrend[3]
                ? parseFloat(liveTrend[3]).toFixed(0).toLocaleString()
                : "0"}
            </p>
            <div className="flex gap-1">
              <img
                src="/img/icon/icon-user-red.png"
                alt="minus icon"
                className="w-[22px] h-[22px]"
              />
              <p className="text-[12px]" style={{ color: "#FAC8C8" }}>
                {liveTrend[2] || 0}
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LiveTrend;
